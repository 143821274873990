<template>
  <div class="error-info-container">
    <h1>{{ $t(`messages.info.head`) }}</h1>
	<p class="error_msg" v-html="$t('messages.invalid.data')"></p>
  </div>
</template>
<script>
export default {
  name: "ErrorInfo",
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
};
</script>
<style scoped>
.error-info-container p {
  margin: 0 0 10px;
}
.error-info-container h1 {
  font-weight: normal;
  font-size: 26px;
  color: #4d148c;
  padding: 0;
  margin: 20px 0 20px 0;
  line-height: 45px;
}
</style>
