const axios = require("axios");

const BASE_URL = process.env.VUE_APP_SERVER_END_POINT;
const MAX_RETRIES = 2;
const country_end_point = {
  au: {
    en: "/apac-peace/au/en/awb"
  },
  id: {
    en: "/apac-peace/id/en/awb"
  },
  ph: {
    en: "/apac-peace/ph/en/awb"
  },
  th: {
    en: "/apac-peace/th/en/awb",
    th: "/apac-peace/th/th/awb"
  },
  hk: {
    en: "/apac-peace/hk/en/awb",
    zh: "/apac-peace/hk/zh/awb"
  },
  kr: {
    en: "/apac-peace/kr/en/awb",
    ko: "/apac-peace/kr/ko/awb"
  },
  sg: {
    en: "/apac-peace/sg/en/awb"
  },
  my: {
    en: "/apac-peace/my/en/awb"
  },
  mo: {
    en: "/apac-peace/mo/en/awb"
  },
  in: {
    en: "/apac-peace/in/en/awb"
  },
  vn: {
    en: "/apac-peace/vn/en/awb"
  },
};

/**
 * Interceptor
 * @returns {promise}
 */
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (err) {
    if (err.response?.status === 401) {
      sessionStorage.removeItem("token");
      let retrySecondCount = localStorage.getItem("retrySecondCount") || 0;
      if (retrySecondCount < MAX_RETRIES) {
        retrySecondCount++;
        localStorage.setItem("retrySecondCount", retrySecondCount);
        const requestData = JSON.parse(err.config["data"]);
        const countryCode = requestData.billPay["country"];
        const res = await getAuthenticationToken(countryCode.toLowerCase());
        sessionStorage.setItem("token", res.token);
        return axios.request(err.config);
      }
      return Promise.reject(err);
    } else if (err.response?.status === 403) {
      const requestData = JSON.parse(err.config["data"]);
      const countryCode = requestData.billPay["country"];
      const res = await getAuthenticationToken(countryCode.toLowerCase());
      sessionStorage.setItem("token", res.token);
      return axios.request(err.config);
    } else if (err.response?.status) {
      return Promise.reject(err);
    } else {
      const resMessage = "Server down! Please try again after sometime.";
      return Promise.reject(resMessage);
    }
  }
);

export async function getAuthenticationToken(countryInfo) {
  const response = await axios.post(`${BASE_URL}/apac-peace/${countryInfo}/auth/token`);
  return response.data;
}

export async function manualAWBConfig(country, params = "", language = "en") {
  const response = await axios.get(
    `${BASE_URL}${country_end_point[country][language]}/billpay?init-params=${params}`
  );
  return response.data;
}

export async function submitAWB(country, data, language = "en") {
  const response = await axios.post(
    `${BASE_URL}${country_end_point[country][language]}/billpay`,
    data
  );
  return response.data;
}

export async function transactionDetail(country, refId, language = "en") {
  const response = await axios.get(
    `${BASE_URL}${country_end_point[country][language]}/billpay/${refId}`
  );
  return response.data;
}
