<template>
  <div class="pay-awb-container container">
    <h1>{{ $t("messages.awb.select.head") }}</h1>
    <BillInfo
      :company="billInfo.company"
      :type="billInfo.type"
      :billDate="billInfo.billDate"
    ></BillInfo>
    <p class="info" v-html="$t('messages.awb.select.text')"></p>
    <div
      class="table_window table-responsive"
      data-emptytext="FedEx - Table Component (Advanced)"
    >
      <table class="table invoice_table short_table">
        <thead>
          <tr>
            <th data-parsys-path="parsys_th_0_4">
              <input
                type="checkbox"
                id="awb_select_all"
                name="awb_select_all"
                value="true"
                v-model="checkedAll"
                @change="checkboxAllBox"
              />
            </th>
            <th data-parsys-path="parsys_th_0_4">
              {{ $t("messages.awb.number") }}
            </th>
            <th data-parsys-path="parsys_th_0_4">
              {{ $t("messages.awb.amount") }} ({{
                $t(`messages['${$filter.getCurrency($countryCode)}']`)
              }})
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="awb in billList" :key="awb.id">
            <td data-parsys-path="parsys_th_0_4">
              <input
                type="checkbox"
                @change="
                  computeTotalAmountOnSel();
                  changeCheckedAll();
                "
                v-model="awb.payFlag"
                checked
              />
            </td>
            <td data-parsys-path="parsys_th_0_4">{{ awb.awbno }}</td>
            <td data-parsys-path="parsys_th_0_4">
              {{ $filter.changeAmountFormate(awb.amount, $countryCode) }}
            </td>
          </tr>
          <tr class="total">
            <td class="td_checkbox"></td>
            <td class="total_text">{{ $t("messages.awb.total") }}</td>
            <td id="total" class="total_amount">
              {{
                $filter.changeAmountFormate(totalAmountToPayOnSel, $countryCode)
              }}
            </td>
          </tr>
        </tbody>

        <tbody></tbody>
      </table>
    </div>
    <div class="awb_table_buttons">
      <button
        id="bill_review"
        type="button"
        class="button"
        name="continue"
        @click="next"
      >
        {{ $t("messages.button.next") }}
      </button>
    </div>
  </div>
</template >

<script>
import BillInfo from "../shared/BillInfo.vue";
export default {
  name: "QRAWBPayment",
  components: {
    BillInfo,
  },
  data() {
    return {
      billInfo: {
        company: String,
        type: String,
        billDate: String,
      },
      billPay: {
        items: [],
      },
      totalAmountToPayOnSel: 0,
      totalAmountToPay: 0,
      billList: [],
      checkedAll: true,
      oldBillPay: [],
    };
  },
  methods: {
    changeCheckedAll() {
      let status = this.billPay.items.some(function (item) {
        return item.payFlag === false;
      });
      this.checkedAll = !status;
      document.getElementById("awb_select_all").checked = !status;
    },
    checkboxAllBox: function () {
      for (var i = 0, len = this.billPay.items.length; i < len; i++) {
        if (this.checkedAll) {
          this.billPay.items[i].payFlag = true;
        } else {
          this.billPay.items[i].payFlag = false;
        }
      }
      this.computeTotalAmountOnSel();
    },
    checkSelectAWB: function () {
      this.billList = this.billPay.items.filter(function (item) {
        if (item.awbno !== null) {
          return item;
        }
      });
      this.computeTotalAmountOnSel();
    },
    computeTotalAmountOnSel: function () {
      var total = 0;
      for (var i = 0; i < this.billPay.items.length; i++) {
        if (this.billPay.items[i].payFlag) {
          total += parseFloat(this.billPay.items[i].amount);
        }
      }
      this.totalAmountToPayOnSel = total;
    },
    next: function () {
      this.billPay.items = this.billPay.items.filter(function (item) {
        if (item.payFlag || item.manuallyAdded) {
          return item;
        }
      });
      if (this.oldBillPay != null) {
        this.oldBillPay = this.oldBillPay.filter(function (item) {
          if (item.manuallyAdded) return item;
        });
        const newArr = this.billPay.items.concat(this.oldBillPay);
        this.oldBillPay = newArr;
      }
      this.billPay.items = this.oldBillPay || this.billPay.items;
      sessionStorage.setItem("billPay", JSON.stringify(this.billPay.items));
      this.$router.push("/PayAWB");
    },
    updateFromlocalstorage() {
      if (this.oldBillPay !== null) {
        for (var i = 0, len = this.billPay.items.length; i < len; i++) {
          const no = this.billPay.items[i].awbno;
          let status = false;
          for (var j = 0, size = this.oldBillPay.length; j < size; j++) {
            if (this.oldBillPay[j].awbno === no) status = true;
          }
          this.billPay.items[i].payFlag = status;
        }
      }
    },
  },
  beforeMount() {
    this.oldBillPay = JSON.parse(sessionStorage.getItem("billPay"));
    const routerQueryParams = JSON.parse(
      sessionStorage.getItem("routerQueryParams")
    );
    const { statementDate, method, awbItems, countryCode, company } =
      routerQueryParams;
    this.billInfo.billDate = statementDate;
    this.billInfo.type = method;
    this.billInfo.company = company;
    sessionStorage.setItem("billInfo", JSON.stringify(this.billInfo));
    sessionStorage.setItem("countryCode", countryCode);
    this.billPay.items = awbItems;
    sessionStorage.setItem("qrPay", true);
    this.updateFromlocalstorage();
  },
  mounted() {
    this.changeCheckedAll();
    this.checkSelectAWB();
  },
};
</script>

<style scoped>
table{
  width: 390px;
}
table.short_table td:first-child {
  min-width: 10px;
}
p.info {
  padding: 10px 0 0 0;
}
.pay-awb-container {
  text-align: left;
  margin-bottom: 20px;
  color: #000;
}
.pay-awb-container h1 {
  font-weight: normal;
  font-size: 26px;
  color: #4d148c;
  padding: 0;
  margin: 20px 0 20px 0;
  line-height: 45px;
}
.pay-awb-container .button {
  background-color: #4d148c;
  color: #fff;
}
.error_msg {
  color: red;
}
.error_border {
  border-color: red;
}
@media (max-width: 768px) {
  #awb_select_all,
  table.short_table td input {
    width: 40px;
  }
}
@media (max-width: 460px) {
  table{
  width: 300px;
  }
}
</style>