<template>
  <div class="select-page-container container">
    <h1>{{ $t("messages.select.head") }}</h1>
    <BillInfo
      :company="billInfo.company"
      :type="billInfo.type"
      :billDate="billInfo.billDate"
    ></BillInfo>
    <p class="info" v-html="$t('messages.select.text')"></p>
    <div
      class="table-responsive table_window"
      data-emptytext="FedEx - Table Component (Advanced)"
    >
      <table class="table invoice_table desktopView">
        <thead>
          <tr>
            <th scope="col" data-parsys-path="parsys_th_0_0">
              <input
                type="checkbox"
                name="invoice_select_all"
                id="invoice_select_all"
                value="true"
                @change="checkboxAllBox"
                v-model="checkedAll"
                checked
              />
            </th>
            <th scope="col" data-parsys-path="parsys_th_0_1">
              {{ $t("messages.invoice.type") }}
            </th>
            <th scope="col" data-parsys-path="parsys_th_0_2">
              {{ $t("messages.invoice.number") }}
            </th>
            <th scope="col" data-parsys-path="parsys_th_0_3">
              {{ $t("messages.invoice.date") }}
            </th>
            <th scope="col" data-parsys-path="parsys_th_0_4">
              {{ $t("messages.invoice.amount") }} ({{
                $t(`messages['${$filter.getCurrency($countryCode)}']`)
              }})
            </th>
          </tr>
        </thead>
        <tbody v-if="billPay !== null">
          <tr v-for="inv in billList" :key="inv.invno">
            <td data-parsys-path="parsys_td_1_0">
              <input
                type="checkbox"
                value="true"
                @change="computeTotalAmountOnSel"
                v-model="inv.payFlag"
              />
            </td>
            <td data-parsys-path="parsys_td_1_1">
              {{ $t(`messages.invoice['${inv.type}']`) }}
            </td>
            <td data-parsys-path="parsys_td_1_2">{{ inv.invno }}</td>
            <td data-parsys-path="parsys_td_1_3">
              {{ $filter.changeDate(inv.date, $i18n.locale) }}
            </td>
            <td data-parsys-path="parsys_td_1_4">
              {{ $filter.changeAmountFormate(inv.dueAmount, $countryCode) }}
            </td>
          </tr>
          <tr class="total">
            <td class="td_checkbox"></td>
            <td></td>
            <td></td>
            <td class="total_text">{{ $t("messages.invoice.total") }}</td>
            <td id="total" class="total_amount">
              {{
                $filter.changeAmountFormate(totalAmountToPayOnSel, $countryCode)
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table mobileTable mobileView">
        <tbody v-if="billPay !== null">
          <tr v-for="inv in billList" :key="inv.invno" class="dataRow">
            <table>
              <tr>
                <td><input
                type="checkbox"
                value="true"
                @change="computeTotalAmountOnSel"
                v-model="inv.payFlag"
              />
              </td>
              <td class="th-header">{{ $t("messages.invoice.type") }}</td>
              <td>{{ $t(`messages.invoice['${inv.type}']`) }}</td>
              </tr>
              <tr>
                <td></td>
                <td class="th-header">{{ $t("messages.invoice.number") }}</td>
                <td>{{ inv.invno }}</td>
              </tr>
              <tr>
                <td></td>
                <td class="th-header">{{ $t("messages.invoice.date") }}</td>
                <td>{{ $filter.changeDate(inv.date, $i18n.locale) }}</td>
              </tr>
              <tr>
                <td></td>
                <td class="th-header">
                  {{ $t("messages.invoice.amount") }} ({{ $t(`messages['${$filter.getCurrency($countryCode)}']`)}})
                </td>
                <td>{{ $filter.changeAmountFormate(inv.dueAmount, $countryCode) }}</td>
              </tr>
            </table>
          </tr>
          <table class="totalRow" style="margin-top:10px">
            <tr>
              <td style="width: 22px"></td>
              <td style="width: 125px" class="th-header">{{ $t("messages.invoice.total") }}</td>
              <td class="th-header">{{$filter.changeAmountFormate(totalAmountToPayOnSel, $countryCode)}}</td>
            </tr>
          </table>
        </tbody>
      </table>
    </div>
    <hr />
    <div class="invoice_table_buttons">
      <button
        id="bill_review"
        type="button"
        class="button"
        name="next"
        @click="next"
      >
        {{ $t("messages.button.next") }}
      </button>
    </div>
  </div>
</template>
<script>
import BillInfo from "../shared/BillInfo.vue";
export default {
  name: "SelectInvoice",
  components: {
    BillInfo,
  },
  data() {
    return {
      billInfo: {
        company: String,
        type: String,
        billDate: String,
      },
      billPay: {
        items: [],
      },
      totalAmountToPayOnSel: 0,
      totalAmountToPay: 0,
      billList: [],
      checkedAll: true,
    };
  },
  methods: {
    checkboxAllBox: function () {
      for (var i = 0, len = this.billPay.items.length; i < len; i++) {
        if (this.checkedAll) {
          this.billPay.items[i].payFlag = true;
        } else {
          this.billPay.items[i].payFlag = false;
        }
      }
      this.computeTotalAmountOnSel();
    },
    checkSelectInvoice: function () {
      this.billList = this.billPay.items.filter(function (item) {
        if (item.type !== null) {
          return item;
        }
      });
      this.computeTotalAmountOnSel();
    },
    computeTotalAmountOnSel: function () {
      var total = 0;
      for (var i = 0; i < this.billPay.items.length; i++) {
        if (this.billPay.items[i].payFlag) {
          total += parseFloat(this.billPay.items[i].dueAmount);
        }
      }
      this.totalAmountToPayOnSel = total;
    },
    next: function () {
      sessionStorage.setItem("billPay", JSON.stringify(this.billPay.items));
      this.$router.push("/PayInvoice");
    },
  },
  beforeMount() {
    const invoiceItemList = sessionStorage.getItem("billPay");
    if (invoiceItemList) {
      const billInfo = JSON.parse(sessionStorage.getItem("billInfo"));
      const { statementDate, method, company } = billInfo;
      this.billInfo.billDate = statementDate;
      this.billInfo.type = method;
      this.billInfo.company = company;
      this.billPay.items = JSON.parse(invoiceItemList);
    } else {
      const routerQueryParams = JSON.parse(
        sessionStorage.getItem("routerQueryParams")
      );
      const { statementDate, method, invoiceItems, countryCode, company } =
        routerQueryParams;
      this.billInfo.billDate = statementDate;
      this.billInfo.type = method;
      this.billInfo.company = company;
      sessionStorage.setItem("billInfo", JSON.stringify(this.billInfo));
      sessionStorage.setItem("countryCode", countryCode);
      this.billPay.items = invoiceItems;
      sessionStorage.setItem("qrPay", true);
    }

  },
  mounted() {
    this.checkSelectInvoice();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info{
  padding-top: 10px;
  padding-bottom: 0;
}
.select-page-container {
  text-align: left;
}
.select-page-container h1 {
  font-weight: normal;
  font-size: 26px;
  color: #4d148c;
  padding: 0;
  margin: 20px 0 20px 0;
  line-height: 45px;
}

.select-page-container .button {
  background-color: #4d148c;
  color: #fff;
}
.th-header{
  font-weight: bold;
}
@media (max-width: 600px) {
  .desktopView{
    display: none;
  }
  .mobileView {
    display: block;
  }
  .dataRow tr:first-child, .totalRow tr:first-child{
    border-top: 1px solid #e5e5e5;
  }
  .totalRow tr td{
    padding-top: 5px;
  }
  .mobileView .dataRow:nth-child(even){
    background-color: #f7f7f7;
  }
  table.mobileTable {
  width: 100%;
  color: #000;
  font-size: 12px;
  }
  table.mobileTable th {
  font-weight: bold;
  color: #000;
  padding: 20px 20px 20px 20px;
  }
  table.mobileTable td {
    padding: 10px 20px 10px 20px;
  }
  table.mobileTable td:last-child {
    width: 180px;
    padding-right: 10px;
  }
  table.mobileTable td:last-child input {
    width: 100px;
    padding: 10px;
  }
  table.mobileTable tr.total {
  border-top: 1px solid #ddd;
  background-color: #fff !important;
  }
  table.mobileTable td.total_text {
    font-weight: bold;
    color: #000;
    text-align: left;
    padding: 20px 0 20px 20px;
  }
  table.mobileTable td.total_amount {
    font-weight: bold;
    color: #000;
    padding: 20px 20px 20px 20px;
  }
  table.mobileTable td,
  table.mobileTable th {
    padding: 10px 0 10px 10px;
  }
}
@media (min-width: 601px) {
  .desktopView{
    display: block;
  }
  .mobileView {
    display: none;
  }
}
</style>
