<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  mounted() {
  },
};
</script>

<style>
#app {
  min-height: calc(50vh - 40px);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app tbody,
td,
tfoot,
th,
thead,
tr,
.table > :not(:first-child) {
  border: none;
}
#app hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #a7a7a7;
}
#app .table {
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  input{
    font-size: 12px;
  }
  table.invoice_table td.total_amount {
    padding: 20px 20px 20px 10px !important;
  }
  table.invoice_table td.total_text {
    padding: 20px 0 20px 10px !important;
  }
}
</style>
