import { createWebHistory, createRouter } from "vue-router";
import ManualInvoiceEntry from "../components/invoice/ManualInvoiceEntry.vue";
import PayInvoice from "../components/invoice/PayInvoice.vue";
import ConfirmPayment from "../components/invoice/ConfirmPaymentPage.vue";
import PaymentStatus from "../components/invoice/PaymentStatus.vue";
import AppRedirection from "../components/shared/AppRedirection.vue";
import ErrorInfo from "../components/shared/ErrorInfo.vue";
import PayAWB from "../components/awb/PayAWB.vue";
import QRAWBPayment from "../components/awb/QRAWBPayment.vue";
import QrpayEntry from "../components/shared/QrpayEntry.vue";
import SelectInvoice from "../components/invoice/SelectInvoice.vue";
import PaymentStatusAWB from "../components/awb/PaymentStatusAWB.vue";
import ConfirmAWBPayment from "../components/awb/ConfirmAWBPaymentPage.vue";
import { getAuthenticationToken } from "../services/service.js";
import GlobalPaymentCCPage from "../components/shared/GlobalPaymentCCPage.vue";
import PaymentRedirection from "../components/shared/PaymentRedirection.vue";
import QRCode from "../components/shared/QRCode.vue";

const routes = [
  {
    path: "/:countryInfo([a-z]{2}-[a-z]{2})/paybill",
    component: ManualInvoiceEntry,
  },
  {
    path: "/QRpay/:countryInfo([a-z]{2})/:locale([a-z]{2})/PaymentStatus/AWB/:pathMatch(.*)*",
    component: PaymentStatusAWB,
    name: "PaymentStatusAWB",
  },
  {
    path: "/QRpay/:countryInfo([a-z]{2})/:locale([a-z]{2})/PaymentStatus/:pathMatch(.*)*",
    name: "PaymentStatus",
    component: PaymentStatus,
  },
  {
    path: "/QRpay/:countryInfo([a-z]{2})/:locale([a-z]{2})?/PaymentRedirection/:pathMatch(.*)*",
    component: PaymentRedirection,
    name: "PaymentRedirection",
  },
  {
    path: "/QRpay/:countryInfo([a-z]{2})/:locale([a-z]{2})?/:pathMatch(.*)*",
    component: QrpayEntry,
    name: "QrpayEntry",
  },
  {
    path: "/SelectInvoice",
    component: SelectInvoice,
    name: "SelectInvoice",
  },
  {
    path: "/PayInvoice",
    component: PayInvoice,
    name: "PayInvoice",
    meta: {},
  },
  {
    path: "/PayAWB",
    component: PayAWB,
    name: "PayAWB",
    meta: {},
  },
  {
    path: "/QRAWBPayment",
    component: QRAWBPayment,
    name: "QRAWBPayment",
    meta: {},
  },
  {
    path: "/ConfirmPayment",
    component: ConfirmPayment,
  },
  {
    path: "/ConfirmAWBPayment",
    component: ConfirmAWBPayment,
  },
  {
    path: "/GlobalPayment",
    component: GlobalPaymentCCPage,
  },
  {
    path: "/QRCode",
    component: QRCode,
  },
  {
    path: "/Error",
    component: ErrorInfo,
    name: "ErrorInfo",
  },
  {
    path: "/:pathMatch(.*)*",
    name: "AppRedirection",
    component: AppRedirection,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    next();
  } else {
    const countryInfo = to.params["countryInfo"];
    const res = await getAuthenticationToken(countryInfo);
    sessionStorage.setItem("token", res.token);
    next();
  }
});

export default router;
