import { createApp } from 'vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import router from './router'
import i18n from './i18n';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import App from './App.vue'
import filters from './filters/filters.js';

const peaceApp = createApp(App)

// createApp(App).use(router).use(i18n).use(BootstrapIconsPlugin).mount('#app')

peaceApp.use(router).use(i18n).use(BootstrapIconsPlugin)
peaceApp.config.globalProperties = {
    ...peaceApp.config.globalProperties,
    $countryCode : String,
    $filter: filters
}

peaceApp.mount('#app')

export default peaceApp