<template>
  <div class="pay-awb-container container">
    <template v-if="qrPay">
      <h1>{{ $t("messages.awb.input['head']") }}</h1>
      <BillInfo
        :company="billInfo.company"
        :type="billInfo.type"
        :billDate="billInfo.billDate"
      ></BillInfo>
      <p class="input-info" v-html="$t(`messages.awb.input['text']`)"></p>
      <InvoiceInstruction :awb="true"></InvoiceInstruction>
    </template>
    <template v-else>
      <h1>{{ $t("messages.awb.entry.head") }}</h1>
      <p v-html="$t('messages.awb.entry.text')"></p>
      <InvoiceInstruction :awb="true"></InvoiceInstruction>
      <p class="company-info">
        {{ $t("messages.bill.to") }}
        <span style="color: red">*</span>:
      </p>
      <p>
        <input
          v-bind:class="{ error_border: !validation.errCompany }"
          v-model="billInfo.company"
          class="input_company"
          minlength="3"
          maxlength="100"
          @input="validation.errCompany = true"
        />
      </p>
      <p id="required" v-bind:class="{ error_msg: !validation.errCompany }">
        <span v-html="$t('messages.awb.entry.required')"></span>
      </p>
    </template>
    <div
      class="table-responsive table_window"
      data-emptytext="FedEx - Table Component (Advanced)"
    >
      <table class="table invoice_table short_table" id="inv_table">
        <thead>
          <tr>
            <th data-parsys-path="parsys_th_0_1">
              {{ $t("messages.awb.number") }}
            </th>
            <th style="padding-right: 0%" data-parsys-path="parsys_th_0_4">
              {{ $t("messages.awb.topay") }} ({{
                $t(`messages['${$filter.getCurrency($countryCode)}']`)
              }})
            </th>
            <!-- <th><div id="add_btn2" @click="addItem"><b-icon-plus-circle /></div></th> -->
            <th>
              <div id="add_btn2" class="plus_icon header_icon" @click="addItem">
                +
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(awb, index) in billList" :key="awb.id">
            <tr v-if="awb.manuallyAdded">
              <td data-parsys-path="parsys_td_1_1">
                <input
                  type="text"
                  size="13"
                  v-bind:class="{ error_border: awb.isErrorBorder }"
                  maxlength="12"
                  v-model="awb.awbno"
                  v-on:keypress="isNumber($event)"
                  @change="checkAWB(awb, index)"
                  class="invoice_id only_allow_numbers awb_add_input"
                />
                <p v-show="awb.errAWB" class="error_msg" v-html="awb.errAWB">
                </p>
              </td>
              <td data-parsys-path="parsys_td_1_4">
                <input
                  type="text"
                  maxlength="15"
                  v-model="awb.amount"
                  v-bind:class="{ error_border: awb.isAmtErrorBorder }"
                  v-on:keypress="isAmount($event)"
                  @change="
                    checkAWB(awb, index);
                    computeTotalAmount(awb.id, awb);
                  "
                  class="billPayAmount invoice_add_input short"
                />
                <p v-show="awb.errAmount" class="error_msg" v-html="awb.errAmount">
                </p>
              </td>
              <td>
                <span>
                  <!-- <div id="icon_one" @click="removeItem(inv.id, inv)" class="remove_btn2 plus_icon"><b-icon-dash-circle /></div> -->
                  <div
                    id="icon_one"
                    @click="removeItem(awb.id, awb)"
                    class="remove_btn2 plus_icon"
                  >
                    &mdash;
                  </div>
                </span>
              </td>
            </tr>
            <tr v-else>
              <td style="vertical-align: middle">
                {{ awb.awbno }}
              </td>
              <td style="vertical-align: middle">
                {{ awb.amount }}
              </td>
              <td style="vertical-align: middle"></td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr class="total">
            <td class="total_text">{{ $t("messages.awb.total") }}</td>
            <td id="total" class="total_amount">
              {{ $filter.changeAmountFormate(totalAmountToPay, $countryCode) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <hr />
    <div class="invoice_table_buttons">
      <button
        id="bill_review"
        type="button"
        class="button"
        v-if="showBackButton"
        name="back"
        @click="back"
        style="margin-right: 5px"
      >
        {{ $t("messages.button.back") }}
      </button>
      <button
        id="bill_review"
        type="button"
        class="button"
        name="next"
        @click="next"
      >
        {{ $t("messages.button.next") }}
      </button>
    </div>
  </div>
</template>

<script>
import BillInfo from "../shared/BillInfo.vue";
import InvoiceInstruction from "../shared/InvoiceInstruction.vue";
import { useRoute } from "vue-router";
export default {
  name: "PayAWB",
  components: {
    BillInfo,
    InvoiceInstruction,
  },
  data() {
    return {
      qrPay: Boolean,
      companyName: "",
      billPay: {
        items: [],
      },
      validation: {
        errCompany: true,
        itemsError: false,
      },
      totalAmountToPayOnSel: 0,
      totalAmountToPay: 0,
      totalAmountPaidUnpaid: 0,
      colAmountHead: "",
      billList: [],
      checkedAll: true,
      page: 0,
      prevRoute: null,
      showBackButton: true,
      billInfo: {
        company: "",
        type: "",
        billDate: "",
      },
    };
  },
  methods: {
    /**
     * confirm form validation and move to confirm payment page
     * @returns {void}
     */
    next: function () {
      if (!this.checkForm() || this.totalAmountToPay === 0) {
        return;
      }
      this.navigateToConfirmPayment();
    },

    /**
     * navigate to confirm payment page
     * @returns {void}
     */
    navigateToConfirmPayment: function () {
      sessionStorage.removeItem("billPay");
      sessionStorage.removeItem("totalAmountToPay");
      this.billList = this.billList.map((bill) => {
        bill.amount = bill.amount.replaceAll(",", "");
        return bill;
      });
      sessionStorage.setItem("billPay", JSON.stringify(this.billList));
      sessionStorage.setItem("billInfo", JSON.stringify(this.billInfo));
      sessionStorage.setItem("totalAmountToPay", JSON.stringify(this.totalAmountToPay));
      // alert('You can navigate to next page Successfully')
      this.$router.push("/ConfirmAWBPayment");
    },
    back: function () {
      sessionStorage.setItem("billPay", JSON.stringify(this.billList));
      this.$router.push("/QRAWBPayment");
    },
    /**
     * add Item check
     * @returns {void}
     */
    addItem: function () {
      if (!this.checkForm()) return;
      this.addNewItem();
    },
    /**
     * add Item
     * @returns {void}
     */
    addNewItem: function () {
      var newItem = {
        awbno: null,
        manuallyAdded: true,
        amount: "0",
        error: true,
        payFlag: false,
      };
      this.runNumber += 1;
      this.billList.push(newItem);
    },
    /**
     * remove Item
     * @returns {void}
     */
    removeItem: function (index, item) {
      if (this.billList.length >= 1) {
        var idx = this.billList.indexOf(item);
        this.billList.splice(idx, 1);
      }
      this.computeTotalAmount(null, null);
    },

    /**
     * check form validation
     * @returns {boolean}
     */
    checkForm: function () {
      var result = true;
      if (this.billInfo.company == null || this.billInfo.company == "") {
        this.validation.errCompany = false;
        result = false;
      } else {
        var len = this.billInfo.company.length;
        if (len < 3 || len > 100) {
          this.validation.errCompany = false;
          result = false;
        } else this.validation.errCompany = true;
      }
      // var awbno = null;
      for (var i = 0; i < this.billList.length; i++) {
        if (!this.checkAWB(this.billList[i], i)) {
          result = false;
        }
      }
      return result;
    },

    /**
     * check AWB validation
     * @returns {boolean}
     */
    checkAWB: function (awb, index) {
      var awbno = awb.awbno;
      var awbamt = awb.amount;
      var pattern = /[^0-9]+/g;
      if (awb.errAWB) {
        awb.errAWB = null;
      }
      if (awb.errAmount) {
        awb.errAmount = null;
      }
      var strInvAmt = awbamt;
      if (strInvAmt && strInvAmt.includes(",")) {
        strInvAmt = strInvAmt.replace(",", "");
      }
      if (awbno == null || awbno == "") {
        this.setError(
          awb,
          index,
          "isErrorBorder",
          true,
          "errAWB",
          this.$t("messages.field.required")
        );
        return false;
      } else if (awbno.length != 12 || pattern.test(awbno)) {
        this.setError(
          awb,
          index,
          "isErrorBorder",
          true,
          "errAWB",
          this.$t("messages.field.awbnum")
        );
        return false;
      } else if (awbamt == null || awbamt == "") {
        this.setError(
          awb,
          index,
          "isAmtErrorBorder",
          true,
          "errAmount",
          this.$t("messages.field.awbRequired")
        );
        return false;
      } else if (awbamt < 1) {
        this.setError(
          awb,
          index,
          "isAmtErrorBorder",
          true,
          "errAmount",
          this.$t("messages.field.awbRequired")
        );
        return false;
      } else {
        this.setError(awb, index, "isErrorBorder", false);
        this.setError(awb, index, "isAmtErrorBorder", false);
        return true;
      }
    },

    /**
     * set error on filed
     * @returns {void}
     */
    setError(
      awb,
      awbIndex,
      errorType = undefined,
      hasError = undefined,
      errorTitle = undefined,
      errorMsg = undefined
    ) {
      this.billList.forEach((bill, index) => {
        if (awbIndex === index) {
          if (errorType !== undefined) {
            bill[errorType] = hasError;
            bill[errorTitle] = errorMsg;
            bill.error = hasError;
          }
        }
      });
    },

    /**
     * compute total Amount
     * @returns {void}
     */
    computeTotalAmount: function (index, item) {
      var total = 0;
      total = this.billList.reduce(function (acc, awb) {
        var amtToPayTotal;
        if (
          !(
            awb.amount !== "0.00" &&
            awb.amount !== "0" &&
            awb.amount != null &&
            !awb.payFlag
          )
        ) {
          if (awb.amount == "") {
            amtToPayTotal = 0;
          } else {
            amtToPayTotal = parseFloat(awb.amount.split(",").join(""));
          }
        } else if (status === "success") {
          amtToPayTotal = parseFloat(awb.amount.split(",").join(""));
        } else if (awb.manuallyAdded && awb.amount != "0") {
          amtToPayTotal = parseFloat(awb.amount.split(",").join(""));
        } else {
          amtToPayTotal = 0;
        }
        if (!isNaN(amtToPayTotal)) {
          return acc + amtToPayTotal;
        }
      }, 0);
      this.totalAmountToPay = total;
      if (index !== null && item !== null) {
        this.formatInvAmt(index, item);
      }
    },

    /**
     * format Intiger amount
     * @returns {void}
     */
    formatInvAmt: function (index, item) {
      var idx = this.billList.indexOf(item);
      var amt = this.billList[idx].amount;
      this.billList[idx].amount = this.$filter.changeAmountFormate(
        amt,
        this.$countryCode
      );
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isAmount: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  beforeMount() {
    this.qrPay = sessionStorage.getItem("qrPay") ? true : false;
    if (this.qrPay) {
      const billInfoFromStorage = sessionStorage.getItem("billInfo");
      const { company, type, billDate } = JSON.parse(billInfoFromStorage);
      this.billInfo.company = company;
      this.billInfo.type = type;
      this.billInfo.billDate = billDate;
      this.billList = [...JSON.parse(sessionStorage.getItem("billPay"))];
      this.billList.forEach((bill) => {
        const amount = bill.amount;
        bill.amount = this.$filter.changeAmountFormate(
          amount,
          this.$countryCode
        );
        bill.error = false;
      });
      this.computeTotalAmount(null, null);
    } else {
      const manuallyEnteredBillList = sessionStorage.getItem("billPay");
      if (manuallyEnteredBillList) {
        const billInfoFromStorage = sessionStorage.getItem("billInfo");
        const { company } = JSON.parse(billInfoFromStorage);
        this.billInfo.company = company;
        this.billList = [...JSON.parse(manuallyEnteredBillList)];
        this.billList.forEach((bill) => {
          const amount = bill.amount || bill.amount;
          bill.amount = this.$filter.changeAmountFormate(
            amount,
            this.$countryCode
          );
        });
        this.computeTotalAmount(null, null);
      } else {
        this.addNewItem();
      }
    }
    this.$countryCode =
      typeof this.$countryCode === "string"
        ? this.$countryCode
        : localStorage.getItem("countryCode");
  },
  mounted() {
    const route = useRoute();
    if (route.query?.entryFrom === "manualAWB") {
      this.showBackButton = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table{
  margin-top : 10px;
}
.company-info {
  padding-top: 10px;
}
.input-info{
  padding-top: 10px;
}
p {
  margin: 0 !important;
}
.pay-awb-container {
  text-align: left;
  margin-bottom: 20px;
  color: #000;
}
.pay-awb-container h1 {
  font-weight: normal;
  font-size: 26px;
  color: #4d148c;
  padding: 0;
  margin: 20px 0 20px 0;
  line-height: 45px;
}
.pay-awb-container .button {
  background-color: #4d148c;
  color: #fff;
}
.error_msg {
  color: red;
}
.error_border {
  border-color: red;
}
</style>
