<template>
  <template v-if="awb">
      <span class="add-remove-instruction">
      <span v-html="$t('messages.awb.entry.add')"></span>
      <div style="position: relative" class="plus-circle example plus_icon">
        +
      </div>
      <span v-if="this.$i18n.locale !== 'zh'"> .</span>
      <span v-else class="chinese-fullstop"> 。</span>
      <span v-html="$t('messages.awb.entry.delete')"></span>
      <div style="position: relative" class="minus-circle example plus_icon">
        &mdash;
      </div>
      <span v-if="this.$i18n.locale != 'th' && this.$i18n.locale !== 'zh'">
        .</span
      >
      <span v-else-if="this.$i18n.locale === 'zh'" class="chinese-fullstop"> 。</span><br /></span
  ></template>
  <template v-else
    ><span
      ><span v-html="$t('messages.entry.add')"></span>
      <div style="position: relative" class="plus-circle example plus_icon">
        +
      </div>
      <span v-if="this.$i18n.locale !== 'zh'"> .</span>
      <span v-else class="chinese-fullstop"> 。</span>
      <span v-html="$t('messages.entry.delete')"></span>
      <div style="position: relative" class="minus-circle example plus_icon">
        &mdash;
      </div>
      <span v-if="this.$i18n.locale != 'th' && this.$i18n.locale !== 'zh'">
        .</span
      >
      <span v-else-if="this.$i18n.locale === 'zh'" class="chinese-fullstop"> 。</span>
      <br /> </span
  ></template>
</template>

<script>
export default {
  name: "InvoiceInstruction",
  props: ["awb"],
  data() {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.add-remove-instruction {
  display: block;
}
.chinese-fullstop {
  vertical-align: -webkit-baseline-middle;
}
</style>
