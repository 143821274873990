<script>
import { useRoute } from 'vue-router'
import peaceApp from '../../main'
import { manualInvoiceConfig } from "../../services/service.js";
export default {
  name: "ManualInvoiceEntry",
  mounted() {
    const route = useRoute()
    const { countryInfo } = route.params
    const [ lang, countryCode, ] = countryInfo.split('-')
    peaceApp.config.globalProperties.$countryCode = countryCode
    this.$i18n.locale = lang
    const inURL = localStorage.getItem('inURL')
    localStorage.clear()
    manualInvoiceConfig(this.$countryCode)
      .then( res => {
        const routerQueryParams = { lang, countryCode, currency: res.currency }
        localStorage.setItem('inURL',inURL)
        localStorage.setItem('routerQueryParams', JSON.stringify(routerQueryParams))
        localStorage.setItem("countryCode", countryCode)
        localStorage.setItem("formatedPayDate", res.formatedPayDate)
        const data = {
          entryFrom : 'manualInvoice'
        }
        this.$router.push({
          name :"PayInvoice",
          query: { ...data },
        });
      })
      .catch( err => {
        this.$router.push({
          name :"ErrorInfo",
        });
        console.log(err)
      })
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
