<script>
import { useRoute } from "vue-router";
import peaceApp from "../../main";
import { checkBPointPaymentStatus } from "../../services/service.js";
export default {
  name: "PaymentRedirection",
  mounted() {
    const route = useRoute();
    const payRef = route.query.payRef;
    const resultKey = route.query.ResultKey;
    const countryCode = route.params.countryInfo;
    const locale = route.params.locale;
    const method = route.params.pathMatch[0];
    checkBPointPaymentStatus(countryCode, resultKey).then((res) => {
      let redirectUrl = "";
      let paymentStatus = res;
      const SUCCESS_MESSAGE = "Success";
      peaceApp.config.globalProperties.$countryCode = countryCode;
      if (method.toLowerCase() === "awb") {
        if (paymentStatus === SUCCESS_MESSAGE) {
          redirectUrl = `/QRpay/${countryCode}/${locale}/PaymentStatus/awb/success?payRef=${payRef}`;
        } else {
          redirectUrl = `/QRpay/${countryCode}/${locale}/PaymentStatus/awb/fail?payRef=${payRef}`;
        }
      } else {
        if (paymentStatus === SUCCESS_MESSAGE) {
          redirectUrl = `/QRpay/${countryCode}/${locale}/PaymentStatus/success?payRef=${payRef}`;
        } else {
          redirectUrl = `/QRpay/${countryCode}/${locale}/PaymentStatus/fail?payRef=${payRef}`;
        }
      }
      this.$router.push(redirectUrl);
    });
  },
};
</script>
