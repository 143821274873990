<template>
  <span
    ><p class="company">{{ $t("messages.bill.to") }}: {{ billPay?.company }}</p>
    <p v-if="billPay?.type === 'S'">
      {{ $t("messages.date.soa") }}: {{ billDate }}
    </p>
    <p v-if="billPay?.type === 'D'">
      {{ $t("messages.date.dl") }}: {{ billDate }}
    </p></span
  >
</template>

<script>
export default {
  name: "BillInfo",
  props: {
    company: String,
    type: String,
    billDate: String,
  },
  data() {
    return {
      billPay: {
        company: String,
        type: String,
        billDate: String,
      },
    };
  },
  mounted() {
    this.billPay.company = this.company;
    this.billPay.type = this.type;
    this.billPay.billDate = this.billDate;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  margin-bottom: 0px !important;
}
.company {
  padding-top: 10px;
}
</style>
