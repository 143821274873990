const axios = require("axios");

const BASE_URL = process.env.VUE_APP_SERVER_END_POINT;
const CLIENT_KEY = process.env.VUE_APP_API_CLIENT_KEY;
const SECRET_KEY = process.env.VUE_APP_API_SECRET_KEY;
// const BASE_URL = 'http://localhost:3002'
const MAX_RETRIES = 3;
const country_end_point = {
  au: {
    en: "/apac-peace/au/en/inv"
  },
  id: {
    en: "/apac-peace/id/en/inv"
  },
  ph: {
    en: "/apac-peace/ph/en/inv",
    gp: "/apac-peace/ph/global-payment"
  },
  th: {
    en: "/apac-peace/th/en/inv",
    th: "/apac-peace/th/th/inv"
  },
  hk: {
    en: "/apac-peace/hk/en/inv",
    zh: "/apac-peace/hk/zh/inv",
    gp: "/apac-peace/hk/global-payment"
  },
  kr: {
    en: "/apac-peace/kr/en/inv",
    ko: "/apac-peace/kr/ko/inv",
    fdk: "/apac-peace/kr/en/inv/billpay"
  },
  sg: {
    en: "/apac-peace/sg/en/inv",
    gp: "/apac-peace/sg/global-payment"
  },
  my: {
    en: "/apac-peace/my/en/inv",
    gp: "/apac-peace/my/global-payment"
  },
  mo: {
    en: "/apac-peace/mo/en/inv",
    gp: "/apac-peace/mo/global-payment"
  },
  in: {
    en: "/apac-peace/in/en/inv"
  },
  vn: {
    en: "/apac-peace/vn/en/inv",
    vi: "/apac-peace/vn/vi/inv"
  }
};

/**
 * Interceptor Request
 * @returns {promise}
 */
axios.interceptors.request.use(async function (config) {
  const token = sessionStorage.getItem("token");
  const payload = {
    clientId: CLIENT_KEY,
    secretKey: SECRET_KEY,
  };
  config.headers["Token-Config"] = window.btoa(JSON.stringify(payload));
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

/**
 * Interceptor Response
 * @returns {promise}
 */
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (err) {
    if (err.response?.status === 401) {
      sessionStorage.removeItem("token");
      let retryCount = localStorage.getItem("retryCount") || 0;
      if (retryCount < MAX_RETRIES) {
        retryCount++;
        localStorage.setItem("retryCount", retryCount);
        const requestData = JSON.parse(err.config["data"]);
        const countryCode = requestData.billPay["country"];
        const res = await getAuthenticationToken(countryCode.toLowerCase());
        sessionStorage.setItem("token", res.token);
        return axios.request(err.config);
      }
      return Promise.reject(err);
    } else if (err.response?.status === 403) {
      const requestData = JSON.parse(err.config["data"]);
      const countryCode = requestData.billPay["country"];
      const res = await getAuthenticationToken(countryCode.toLowerCase());
      sessionStorage.setItem("token", res.token);
      return axios.request(err.config);
    }
    else if (err.response?.status) {
      return Promise.reject(err);
    } else {
      const resMessage = "Server down! Please try again after sometime.";
      return Promise.reject(resMessage);
    }
  }
);

export async function getAuthenticationToken(countryInfo) {
  const response = await axios.post(`${BASE_URL}/apac-peace/${countryInfo}/auth/token`);
  return response.data;
}

export async function validateInvoices(country, data, language = "en") {
  const response = await axios.post(
    `${BASE_URL}${country_end_point[country][language]}/billpay/validation`,
    data
  );
  return response.data;
}

export async function manualInvoiceConfig(country, params = "", language = "en") {
  const response = await axios.get(
    `${BASE_URL}${country_end_point[country][language]}/billpay?init-params=${params}`
  );
  return response.data;
}

export async function submitInvoices(country, data, language = "en") {
  const response = await axios.post(
    `${BASE_URL}${country_end_point[country][language]}/billpay`,
    data
  );
  return response.data;
}

export async function transactionDetail(country, refId, language = "en") {
  const response = await axios.get(
    `${BASE_URL}${country_end_point[country][language]}/billpay/${refId}`
  );
  return response.data;
}

export async function checkVersion(country, payload) {
  const response = await axios.post(
    `${BASE_URL}${country_end_point[country]['gp']}/authenticate/check-version`, payload
  );
  return response.data;
}

export async function initiateAuthentication(country, payload) {
  const response = await axios.post(
    `${BASE_URL}${country_end_point[country]['gp']}/authenticate/initiate-authentication`, payload
  );
  return response.data;
}

export async function notifyChallange(country, payload) {
  const response = await axios.post(
    `${BASE_URL}${country_end_point[country]['gp']}/notify/challenge`, payload
  );
  return response.data;
}

export async function checkBPointPaymentStatus(country, resultKey) {
  const response = await axios.get(`${BASE_URL}${country_end_point[country]["en"]}/bpoint/query?resultKey=${resultKey}`);
  return response.data;
}

export async function sendFDKSubmit(country, payload, mobileFlag) {
  const response = await axios.post(
    `${BASE_URL}${country_end_point[country]['fdk']}?mobileFlag=${mobileFlag}`, payload
  );
  return response.data;
}

export async function checkFdkPaymentStatus(country, payRef) {
  const response = await axios.get(
    `${BASE_URL}${country_end_point[country]['fdk']}/pay/${payRef}`
  );
  return response.data;
}

export async function getGPChallangeResult(country, payload) {
  const response = await axios.post(
    `${BASE_URL}${country_end_point[country]['gp']}/authenticate/challenge-result`, payload
  );
  return response.data;
}