<template>
  <div
    class="table-responsive table_window"
    data-emptytext="FedEx - Table Component (Advanced)"
  >
    <table id="inv_table" class="table invoice_table short_table">
      <thead>
        <tr>
          <th data-parsys-path="parsys_th_0_1" style="padding-right: 0%">
            <template v-if="awb">
              {{ $t("messages.awb.number") }}
            </template>
            <template v-else>
              {{ $t("messages.invoice.number") }}
            </template>
          </th>
          <th style="padding-right: 0%" data-parsys-path="parsys_th_0_4">
            {{ colamountheader }} ({{ currency }})
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="awb">
          <tr v-for="awb in billPay" :key="awb.awbno">
            <td data-parsys-path="parsys_td_1_1">
              <div data-emptytext="Rich Text">{{ awb.awbno }}</div>
            </td>
            <td data-parsys-path="parsys_td_1_4">
              <div data-emptytext="Rich Text">
                {{ $filter.changeAmountFormate(awb.amount, country) }}
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr v-for="inv in billPay" :key="inv.invno">
            <td data-parsys-path="parsys_td_1_1">
              <div data-emptytext="Rich Text">{{ inv.invno }}</div>
            </td>
            <td data-parsys-path="parsys_td_1_4">
              <div data-emptytext="Rich Text">
                {{ $filter.changeAmountFormate(inv.payAmount, country) }}
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr class="total">
          <td class="total_text">{{ statusinfo }}</td>
          <td id="total" class="total_amount">
            {{ $filter.changeAmountFormate(totalamount, country) }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: "BillTable",
  props: {
    statusinfo: String,
    totalamount: String,
    billPay: [],
    colamountheader: String,
    currency: String,
    country: String,
    awb: Boolean,
  },
};
</script>
