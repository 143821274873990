<template>
  <LoadingSpinner v-if="showLoading" />
  <div class="confirm-payment-container container">
    <h1>{{ $t("messages.awb.confirm.head") }}</h1>
    <BillInfo
      :company="billInfo.company"
      :type="billInfo.type"
      :billDate="billInfo.billDate"
    ></BillInfo>
    <p class="bill-info">
      {{ $t("messages.awb.paytotal") }}:
      {{ $t(`messages['${$filter.getCurrency($countryCode)}']`) }}
      {{ $filter.changeAmountFormate(totalAmountToPay, $countryCode) }}
    </p>
    <p>{{ $t("messages.bill.trandate") }}: {{ payDate }}</p>

    <p v-html="$t('messages.awb.confirm.text')"></p>

    <!-- Email starts -->
    <p v-html="$t(`messages.awb.confirm.email`)" class="email-label"></p>
    <input
      type="email"
      v-bind:class="{ error_border: !validation.errEmail }"
      @change="checkEmail"
      class="input_email"
      v-model="emailAddress"
      maxlength="100"
    />
    <span
      class="error_msg amtreq_span"
      id="err_email"
      v-show="!validation.errEmail"
      ><div v-html="$t(`messages.field.invmail`)"></div
    ></span>
    <p class="payment_method_option" v-if="$countryCode == 'ph'">
      <input
        type="radio"
        value="CREDIT_CARD"
        v-model="selectedPaymentMethod"
        @change="validation.paymentMethod = true"
      />
      <label
        v-html="$t(`messages.paymentMethod.creditCard`)"
        @click="changePaymentMethod('CREDIT_CARD')"
      ></label>
      <br />
      <input
        type="radio"
        value="eWallet"
        v-model="selectedPaymentMethod"
        @change="validation.paymentMethod = true"
      />
      <label
        v-html="$t(`messages.paymentMethod.eWallet`)"
        @click="changePaymentMethod('eWallet')"
      ></label>
    </p>
    <p v-if="$countryCode == 'ph'">
      <span
        class="error_msg amtreq_span"
        id="err_paymentMethod"
        v-show="!validation.paymentMethod"
        ><div v-html="$t(`messages.field.paymentMethod`)"></div
      ></span>
    </p>
    <p class="payment_method_option" v-if="$countryCode === 'sg' || $countryCode === 'hk'">
        <input
          type="radio"
          value="CREDIT_CARD"
          v-model="selectedPaymentMethod"
          @change="validation.paymentMethod = true"
        />
        <label
          v-html="$t(`messages.paymentMethod.creditCard`)"
          @click="changePaymentMethod('CREDIT_CARD')"
        ></label>
        <br />
        <input
          type="radio"
          value="BANK_TRANSFER"
          v-model="selectedPaymentMethod"
          @change="validation.paymentMethod = true"
        />
        <label
          v-html="$t(`messages.paymentMethod.bankTransfer`)"
          @click="changePaymentMethod('BANK_TRANSFER')"
        ></label>
      <span
        class="error_msg amtreq_span"
        id="err_paymentMethod"
        v-show="!validation.paymentMethod"
        ><div v-html="$t(`messages.field.paymentMethod`)"></div
      ></span>
      </p>
    <p id="required" v-html="$t(`messages.entry.required`)"></p>
    <!-- Email Ends -->

    <div
      class="table-responsive table_window"
      data-emptytext="FedEx - Table Component (Advanced)"
    >
      <table
        class="table invoice_table short_table"
        id="inv_table"
        style="width: 40%"
      >
        <thead>
          <tr>
            <th data-parsys-path="parsys_th_0_1">
              {{ $t("messages.awb.number") }}
            </th>
            <th style="padding-right: 0%" data-parsys-path="parsys_th_0_4">
              {{ $t("messages.awb.topay") }} ({{
                $t(`messages['${$filter.getCurrency($countryCode)}']`)
              }})
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="awb in checkPayFlag" :key="awb.id">
            <td data-parsys-path="parsys_td_1_1">
              <div data-emptytext="Rich Text">{{ awb.awbno }}</div>
            </td>
            <td data-parsys-path="parsys_td_1_4">
              <div data-emptytext="Rich Text">
                {{ $filter.changeAmountFormate(awb.amount, $countryCode) }}
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="total">
            <td class="total_text">{{ $t("messages.awb.paytotal") }}</td>
            <td id="total" class="total_amount">
              {{ $filter.changeAmountFormate(totalAmountToPay, $countryCode) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <hr />
    <div class="invoice_table_buttons" style="margin-bottom: 20px">
      <p>
        <b v-show="isForRedirect()" class="invoice_confirm_message" v-html="$t('messages.awb.confirm.payment')"></b>
        <b v-show="isSurchargeCountries()" class="invoice_confirm_message" v-html="$t('messages.awb.confirm.surcharge')"></b>
      </p>
      <button id="bill_detail" class="button" @click="back" name="back">
        {{ $t("messages.button.back") }}
      </button>
      <button id="bill_pay" class="button" @click="sendToPayDollar">
        {{ $t("messages.button.next") }}
      </button>
    </div>
  </div>
</template>

<script>
import BillInfo from "../shared/BillInfo.vue";
import LoadingSpinner from "../shared/LoadingSpinner.vue";
import { submitAWB } from "../../services/service-AWB.js";

export default {
  name: "ConfirmAWBPayment",
  components: {
    BillInfo,
    LoadingSpinner,
  },
  data() {
    return {
      totalAmountToPay: 0,
      billList: [],
      checkedAll: true,
      page: 0,
      prevRoute: null,
      emailAddress: sessionStorage.getItem("emailAddress") || "",
      payDate: sessionStorage.getItem("formatedPayDate"),
      validation: {
        errEmail: true,
        paymentMethod: true,
      },
      billInfo: {
        company: String,
        type: String,
        billDate: String,
      },
      selectedPaymentMethod: "",
      showLoading: false,
    };
  },
  beforeMount() {
    const billInfoFromStorage = sessionStorage.getItem("billInfo");
    const oldEmailAddress = sessionStorage.getItem("email");
    if (oldEmailAddress) this.emailAddress = oldEmailAddress;
    const { company, type, billDate } = JSON.parse(billInfoFromStorage);
    this.billInfo.company = company;
    this.billInfo.type = type;
    this.billInfo.billDate = billDate;
    this.billList = [...JSON.parse(sessionStorage.getItem("billPay"))];
    this.$countryCode =
      typeof this.$countryCode === "string"
        ? this.$countryCode
        : localStorage.getItem("countryCode");
  },
  computed: {
    checkPayFlag: function () {
      return this.billList.filter(function (item) {
        if (item.manuallyAdded && !item.payFlag) {
          return item;
        }
        if (item.awbno != null && item.awbno !== "" && item.payFlag) {
          return item;
        }
      });
    },
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.computeTotalAmount(null, null);
    this.convertTodaysDate();
  },
  methods: {
    isForRedirect() {
      const redirectCountries = ["th", "kr", "id", "ph", "in", "vn"];
      if (redirectCountries.includes(this.$countryCode)) {
        if (this.selectedPaymentMethod === "CREDIT_CARD") {
          return false;
        }
        return true;
      }
      else {
        return false;
      }
    },
    isSurchargeCountries() {
      const surchargeCountries = ["th", "id", "ph", "sg", "hk", "vn"];
      return surchargeCountries.includes(this.$countryCode);
    },
    /**
     * convert today's date into readable formate
     * @returns {string}
     */
    convertTodaysDate() {
      let convertedDate = "";
      const year = new Date().getFullYear().toString().substr(2);
      let month = (new Date().getMonth() + 1).toString();
      if (month.length === 1) {
        month = "0" + month;
      }
      const day = new Date().getDate().toString();
      convertedDate = year + month + day;
      return convertedDate;
    },

    /**
     * Check email validity
     * @returns {boolean}
     */
    checkEmail: function () {
      var resultEmail = true;
      if (this.emailAddress == null || this.emailAddress == "") {
        this.validation.errEmail = false;
        resultEmail = false;
      } else {
        var len = this.emailAddress.length;
        if (len < 3 || len > 100) {
          this.validation.errEmail = false;
          resultEmail = false;
        } else {
          //non-english characters
          var non_eng = /^.+@.+\..+$/;
          //english characters
          var eng_chr = /[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/;
          if (non_eng.test(this.emailAddress)) {
            this.validation.errEmail = true;
          } else if (eng_chr.test(this.emailAddress)) {
            this.validation.errEmail = true;
          } else {
            this.validation.errEmail = false;
            resultEmail = false;
          }
        }
      }
      return resultEmail;
    },

    /**
     * navigate application to payment vendor's page
     * @returns {void}
     */
    sendToPayDollar: function () {
      const GPCountries = ["my", "mo"];
      if (this.checkEmail()) {
        if (this.selectedPaymentMethod === "" && 
              (this.$countryCode === "ph" || this.$countryCode === "sg" || this.$countryCode === "hk")) {
          this.validation.paymentMethod = false;
          return;
        }
        this.showLoading = true;
        const payload = this.createPayload();
        submitAWB(this.$countryCode, payload, this.$i18n.locale)
          .then((res) => {
            if (
              GPCountries.includes(this.$countryCode) ||
              (this.$countryCode === "ph" && this.selectedPaymentMethod === "CREDIT_CARD") || 
              (this.$countryCode === "sg" && this.selectedPaymentMethod === "CREDIT_CARD") ||
              (this.$countryCode === "hk" && this.selectedPaymentMethod === "CREDIT_CARD")
            ) {
              sessionStorage.setItem("emailAddress", this.emailAddress);
              sessionStorage.setItem("GPSessionStorage", res.sessionToken);
              sessionStorage.setItem("payRef", res.payRef);
              this.showLoading = true;
              this.$router.push("GlobalPayment");
            } 
            else if ((this.$countryCode === "sg" && this.selectedPaymentMethod === "BANK_TRANSFER") || 
                      (this.$countryCode === "hk" && this.selectedPaymentMethod === "BANK_TRANSFER")) {
              sessionStorage.setItem("payRef", res.payRef);
              if(this.$countryCode === "sg")
                  sessionStorage.setItem("payNowRef", res.payNowRef);
                else
                  sessionStorage.setItem("fpsRef", res.fpsRef);
              sessionStorage.setItem("codeUrl", res.codeUrl);
              // Retrieve the timelimit value from the back-end API
              sessionStorage.setItem("timelimit", res.timelimit);
              this.showLoading = false;
                this.$router.push("QRCode");
            }
            else {
              this.showLoading = true;
              window.location.replace(res.url);
            }
          })
          .catch((err) => {
            this.showLoading = false;
            console.error(err);
            this.$router.push({
              name: "ErrorInfo",
            });
          });
      } else {
        if (this.selectedPaymentMethod === "") {
          this.validation.paymentMethod = false;
        }
        console.log("Error");
      }

      // this.$router.push("/ConfirmPayment");
    },

    /**
     * create payload for REST call
     * @returns {object}
     */
    createPayload() {
      var outBillPay = JSON.stringify(this.billList);
      var stripped = outBillPay.replace(/,(?!["{}[\]])/g, "");
      let paymentMethod;
      if (this.$countryCode === "ph" || this.$countryCode === "sg" || this.$countryCode === "hk") {
        paymentMethod = this.selectedPaymentMethod;
      }
      const formatedPayDate = sessionStorage.getItem("formatedPayDate");
      const billList = JSON.parse(stripped);
      let routerQueryParams = sessionStorage.getItem("routerQueryParams");
      if (routerQueryParams) {
        routerQueryParams = JSON.parse(routerQueryParams);
      }
      const awbItems = billList.map((awb) => {
        if (awb.manuallyAdded && !awb.payFlag) {
          awb.payFlag = true;
        }
        return {
          awbno: awb.awbno,
          amount: awb.amount,
          payFlag: awb.payFlag,
        };
      });
      const URL = window.location.protocol + "//" + window.location.host;
      const payload = {
        successUrl: `${URL}/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/AWB/success`,
        failureUrl: `${URL}/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/AWB/fail`,
        redirectUrl: `${URL}/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentRedirection/AWB`,
        paymentMethod,
        billPay: {
          country: routerQueryParams.countryCode,
          language: routerQueryParams.lang,
          currency: routerQueryParams.currency,
          versionQR: routerQueryParams.versionQR,
          type: routerQueryParams.method,
          emailAddress: this.emailAddress,
          company: this.billInfo.company,
          stationId: routerQueryParams.stationId,
          awbItems,
          formatedPayDate,
        },
      };
      return payload;
    },
    back: function () {
      sessionStorage.setItem("emailAddress", this.emailAddress);
      this.$router.back();
    },
    /**
     * Calculate total amount
     * @returns {void}
     */
    computeTotalAmount: function (index, item) {
      var total = 0;
      total = this.billList.reduce(function (acc, awb) {
        var amtToPayTotal;
        if (
          !(
            awb.amount !== "0.00" &&
            awb.amount !== "0" &&
            awb.amount != null &&
            !awb.payFlag
          )
        ) {
          if (awb.amount == "") {
            amtToPayTotal = 0;
          } else {
            amtToPayTotal = parseFloat(awb.amount.split(",").join(""));
          }
        } else if (status === "success") {
          amtToPayTotal = parseFloat(awb.amount.split(",").join(""));
        } else if (awb.manuallyAdded && awb.amount != "0") {
          amtToPayTotal = parseFloat(awb.amount.split(",").join(""));
        } else {
          amtToPayTotal = 0;
        }
        if (!isNaN(amtToPayTotal)) {
          return acc + amtToPayTotal;
        }
      }, 0);
      this.totalAmountToPay = total;
      if (index !== null && item !== null) {
        this.formatInvAmt(index, item);
      }
    },
    changePaymentMethod(paymentSelectedMethod) {
      this.selectedPaymentMethod = paymentSelectedMethod;
      this.validation.paymentMethod = true;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.fullPath;
    });
  },
};
</script>

<style scoped>
.bill-info{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.email-label{
  margin-bottom: 0 !important;
}
.confirm-payment-container {
  text-align: left;
  color: #000;
}
.confirm-payment-container p {
  margin: 0 0 10px;
}
.confirm-payment-container h1 {
  font-weight: normal;
  font-size: 26px;
  color: #4d148c;
  padding: 0;
  margin: 20px 0 20px 0;
  line-height: 45px;
}
.confirm-payment-container .button {
  background-color: #4d148c;
  color: #fff;
  margin-right: 5px;
}
.error_msg {
  color: red;
}
.error_border {
  border-color: red;
}
.payment_method_option {
  margin-top: 20px !important;
}
.payment_method_option label {
  margin-left: 5px;
  margin-bottom: 5px;
}
</style>
