<template>
    <div class="confirm-payment-container container">
        <h1 class="head">{{ $t("messages.qrCode.head") }}</h1>
        <div class="qr-code-info">
            <p class="instruction">
                {{ $t("messages.qrCode.instructionStart") }}
                <b v-if="timelimit > 0">{{ timer }}</b>
                {{ $t("messages.qrCode.instructionMid") }}
                <b v-if="$countryCode === 'sg'">{{ $t("messages.qrCode.federalExpSgLtd") }}</b>
                <b v-else-if="$countryCode === 'hk'">{{ $t("messages.qrCode.federalExpHkLtd") }}</b>
                {{ $t("messages.qrCode.instructionEnd") }}
            </p>
            <ol class="steps">
                <li>
                    {{ $t("messages.qrCode.step1") }}
                </li>
                <li>
                    {{ $t("messages.qrCode.step2") }}<img class="scan" src="../../assets/scan.png" alt="scan" width="10"
                        height="10">
                </li>
                <li>
                    {{ $t("messages.qrCode.step3") }}
                </li>
                <li>
                    {{ $t("messages.qrCode.step4a") }}
                    <b>{{ $t("messages.qrCode.step4b") }}</b>
                    {{ $t("messages.qrCode.step4c") }}
                </li>
                <li>{{ $t("messages.qrCode.step5") }}</li>
            </ol>
            <p class="amount-info">
                <label><b>{{ $t("messages.qrCode.totalPayment") }}</b></label>
                <label class="amount">
                    {{ $t(`messages['${$filter.getCurrency($countryCode)}']`) }}
                    {{ $filter.changeAmountFormate(totalAmount, $countryCode) }}
                    {{ $t("messages.qrCode.dollerSymbol") }}
                </label>
            </p>
            <p class="time-expiry">
                <label v-if="$countryCode === 'sg'">
                    {{ $t("messages.qrCode.payBefore") }} <span>{{ expiry }}</span> {{ $t("messages.qrCode.sgt") }}
                </label>
                <label v-else-if="$countryCode === 'hk'">
                    {{ $t("messages.qrCode.payBefore") }} <span>{{ expiry }}</span> {{ $t("messages.qrCode.hkt") }}
                </label>
            </p>
            <div class="qr-code" v-if="$countryCode === 'sg'">
                <QRCodeVue3 :value="codeUrl" :width="200" :height="200"
                    :qrOptions="{ typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' }"
                    :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
                    :dotsOptions="{ type: 'square', color: '#7C1A78' }" image="img/citipaynow.png"
                    :cornersSquareOptions="{ type: 'square', color: '#7C1A78' }"
                    :cornersDotOptions="{ type: 'square', color: '#7C1A78' }" :download="false" />
                <button class="download-qr-btn" @click="downloadQR" v-if="smartphone != 'ios'">
                    {{ $t("messages.qrCode.saveQRCode") }}
                </button>
            </div>
            <div class="qr-code" v-else-if="$countryCode === 'hk'">
                <QRCodeVue3 :value="codeUrl" :width="200" :height="200"
                    :qrOptions="{ typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' }"
                    :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
                    :dotsOptions="{ type: 'square', color: '#000000' }"
                    :cornersSquareOptions="{ type: 'square', color: '#000000' }"
                    :cornersDotOptions="{ type: 'square', color: '#000000' }" :download="false" />
                <button class="download-qr-btn" @click="downloadQR" v-if="smartphone != 'ios'">
                    {{ $t("messages.qrCode.saveQRCode") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import QRCodeVue3 from "qrcode-vue3";
import moment from 'moment-timezone';
import SockJS from "sockjs-client";
import Stomp from "stompjs";

export default {
    name: 'QRCode',
    components: {
        QRCodeVue3,
    },
    data() {
        return {
            timelimit: 0, // In Seconds
            timer: '00:00:00',
            expiry: null,
            endAt: null,
            payRef: "",
            citiRef: "",
            codeUrl: "",
            totalAmount: 0,
            stompClient: null,
            paymentStatus: null,
            smartphone: 'others',
            lang: null,
            options: {
                width: 300,
                height: 300,
                data: "https://qr-code-styling.com",
                margin: 0,
                qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
                imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
                dotsOptions: { type: "square", color: "#05004d" },
                backgroundOptions: { color: "#ffffff" },
                image: "citipaynow.png",
                dotsOptionsHelper: {
                    colorType: { single: true, gradient: false },
                    gradient: {
                        linear: true,
                        radial: false,
                        color1: "#6a1a4c",
                        color2: "#6a1a4c",
                        rotation: "0",
                    },
                },
                cornersSquareOptions: { type: "square", color: "#0e013c" },
                cornersSquareOptionsHelper: {
                    colorType: { single: true, gradient: false },
                    gradient: {
                        linear: true,
                        radial: false,
                        color1: "#000000",
                        color2: "#000000",
                        rotation: "0",
                    },
                },
                cornersDotOptions: { type: "square", color: "#04003d" },
                cornersDotOptionsHelper: {
                    colorType: { single: true, gradient: false },
                    gradient: {
                        linear: true,
                        radial: false,
                        color1: "#000000",
                        color2: "#000000",
                        rotation: "0",
                    },
                },
                backgroundOptionsHelper: {
                    colorType: { single: true, gradient: false },
                    gradient: {
                        linear: true,
                        radial: false,
                        color1: "#ffffff",
                        color2: "#ffffff",
                        rotation: "0",
                    },
                },
            },
        };
    },
    mounted() {
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i)) {
            this.smartphone = 'ios';
        } else if (navigator.userAgent.toLowerCase().includes('android')) {
            this.smartphone = 'android';
        }
        let routerQueryParams = sessionStorage.getItem("routerQueryParams");
        if (routerQueryParams) {
            routerQueryParams = JSON.parse(routerQueryParams);
            this.lang = routerQueryParams.lang;
        }
        this.payRef = sessionStorage.getItem("payRef");
        this.citiRef = this.$countryCode === "sg" ? sessionStorage.getItem("payNowRef") : sessionStorage.getItem("fpsRef");
        this.codeUrl = sessionStorage.getItem("codeUrl");
        this.timelimit = sessionStorage.getItem("timelimit");
        this.endAt = moment(new Date()).add(+(this.timelimit), 'seconds').tz('Asia/Singapore');
        const dateFormat = this.lang === 'zh' ? 'DD MMMM YYYY h:mma' : 'DD MMMM YYYY [at] h:mma';        
        this.expiry = this.endAt.format(dateFormat);
        const billPay = JSON.parse(sessionStorage.getItem("billPay"));
        billPay.forEach((bill) => {
            if (bill.payFlag && bill.payAmount > 0) {
                this.totalAmount += Number(bill.payAmount);
            } else if (!bill.payFlag && bill.dueAmount < 1) {
                this.totalAmount += Number(bill.payAmount);
            } else if (bill.amount > 0) {
                this.totalAmount += Number(bill.amount);
            }
        });
        if (this.timelimit > 0) {
            this.countDown();
        }

        // Websocket connection required for receiving the paynow notification from backend
        this.connect();
    },
    methods: {

        countDown() {
            const interval = setInterval(() => {
                let now = moment(new Date()).tz('Asia/Singapore');
                let diffInSec = moment.utc(this.endAt).diff(now, 'seconds');
                this.timer = moment.utc(diffInSec * 1000).format('HH:mm:ss');
                if (diffInSec <= 0) {
                    this.navigateToStatusPage();
                    clearInterval(interval);
                }
            }, 1000);
        },

        downloadQR() {
            let qrElem = document.querySelector('.confirm-payment-container .qr-code img');
            var qrLink = document.createElement('a');
            qrLink.setAttribute('href', qrElem.getAttribute('src'));
            qrLink.setAttribute('target', '_blank');
            qrLink.download = `${this.citiRef}.png`;
            qrLink.click();
        },

        connect() {
            const connectEndpoint = this.$countryCode === "sg" ? '/apac-peace/sg/connect' : '/apac-peace/hk/connect';
            console.log('connectEndpoint',connectEndpoint)
            const socket = new SockJS(process.env.VUE_APP_SERVER_END_POINT + connectEndpoint, {
                cors: {
                    origin: process.env.VUE_APP_SERVER_END_POINT,
                    credentials: true
                }
            });
            this.stompClient = Stomp.over(socket);

            if (!this.stompClient.connected) {
                this.stompClient.connect(
                    {}, () => {
                        this.stompClient.subscribe("/topic/messages", (frame) => {
                            if (frame && frame.body) {                            
                                const eventCitiRef = this.$countryCode === "sg" ?  JSON.parse(frame.body).payNowRef : JSON.parse(frame.body).payFPSRef;
                                if (this.citiRef && eventCitiRef && this.citiRef == eventCitiRef) {
                                    this.paymentStatus = JSON.parse(frame.body).status;
                                    this.navigateToStatusPage();
                                }
                            } else {
                                console.log('Recieved Event data is null');
                            }
                        });
                    }, error => {
                        console.error('ERROR', error);
                        // this.$router.push({
                        //     name: "ErrorInfo",
                        // });
                    });
            } else {
                console.warn('Socket is already connected')
            }
        },

        disconnect() {
            if (this.stompClient) {
                this.stompClient.disconnect();
            }
        },

        navigateToStatusPage() {
            if (this.$router.currentRoute.value.query.method === "invoice") {
                if (this.paymentStatus === "PAYMENT_SUCCESS") {
                    this.$router.push(`/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/success?payRef=${this.payRef}`);
                } else {
                    this.$router.push(`/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/fail?payRef=${this.payRef}`);
                }
            } else {
                if (this.paymentStatus === "PAYMENT_SUCCESS") {
                    this.$router.push(`/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/awb/success?payRef=${this.payRef}`);
                } else {
                    this.$router.push(`/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/awb/fail?payRef=${this.payRef}`);
                }
            }
        }
    }
}
</script>

<style scoped>
.head {
    font-weight: normal;
    font-size: 26px;
    color: #4d148c;
    padding: 0;
    margin: 20px 0 20px 0;
    line-height: 45px;
}

.qr-code-info {    
    text-align: justify;
}

.qr-code-info .steps {
    padding-left: 12px;
}

.qr-code-info .steps .scan {
    margin-bottom: 1px;
}

.qr-code-info .amount-info {
    max-width: 35rem;
}

.qr-code-info .amount-info .amount {
    float: right;
}

.qr-code-info .time-expiry {
    max-width: 35rem;
    margin-top: 1.5rem !important;
    text-align: center;
}

.qr-code-info .time-expiry label {
    font-size: 16px;
    font-weight: bold;
}

.qr-code-info .qr-code {
    max-width: 35rem;
    margin-top: 1.5rem !important;
    text-align: center;
}

.qr-code-info .qr-code .download-qr-btn {
    margin-top: 1rem;
}

.qr-code-info .qr-code .save-qr-message {
    padding-top: 1rem;
} 
</style>