import moment from "moment";
const filters = {
  /**
   * change Date format
   * @returns {string}
   */
  changeDate: function (value, locate) {
    let formatedDate = "";
    const year = "20" + value.slice(0, 2);
    const month = value.slice(2, 4);
    const date = value.slice(4, 6);
    const givenDate = new Date(year, month - 1, date);
    switch (locate) {
      case "ko":
        // formatedDate = givenDate.toLocaleDateString('ko-KR', options);
        formatedDate = moment(givenDate).locale("ko").format("YYYY년MM월DD일");
        break;
      case "en":
        formatedDate = moment(givenDate).locale("en").format("DD-MM-YYYY");
        break;
      case "zn":
        formatedDate = moment(givenDate).locale("zn").format("YYYY年MMM月Do日");
        break;
      default:
        formatedDate = moment(givenDate).format().slice(0, 10);
        break;
    }
    return formatedDate;
  },

  /**
   * change amount format
   * @returns {string}
   */
  changeAmountFormate: function (value, countryCode) {
    let formtedAmount = "";
    switch (countryCode) {
      case "kr":
        formtedAmount = this.formatAmt(value, 0);
        break;
      case "id":
        formtedAmount = this.formatAmt(value, 0);
        break;
      case "th":
        formtedAmount = this.formatAmt(value, 2);
        break;
      case "ph":
        formtedAmount = this.formatAmt(value, 2);
        break;
      case "hk":
        formtedAmount = this.formatAmt(value, 2);
        break;
      case "sg":
        formtedAmount = this.formatAmt(value, 2);
        break;
      case "my":
        formtedAmount = this.formatAmt(value, 2);
        break;
      case "mo":
        formtedAmount = this.formatAmt(value, 2);
        break;
      case "in":
        formtedAmount = this.formatAmtWithCtry(value, 2, countryCode);
        break;
      case "vn":
          formtedAmount = this.formatAmt(value, 0);
          break;
      default:
        formtedAmount = this.formatAmt(value, 2);
        break;
    }
    return formtedAmount;
  },
  
  formatAmt(number, precison) {
    return this.formatAmtWithCtry(number, precison, null);
  },

  formatAmtWithCtry(number, precison, countryCode) {
    var x;
    var x1;
    var x2;
    var amt = "";
    if (isNaN(number)) {
      if (number) {
        if (number.includes(",")) {
          amt = number.split(",").join("");
        } else {
          amt = 0;
        }
      } else {
        amt = 0;
      }
    } else {
      amt = number;
    }
    x = parseFloat(Math.round(amt * 100) / 100)
      .toFixed(precison)
      .split(".");
    x1 = x[0];
    x2 = x.length > 1 ? "." + x[1] : "";
    
    if(countryCode === 'in'){
      x1 = new Intl.NumberFormat('en-IN').format(x1);
    } else {
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
    }    
    var amtFormat = x1 + x2;
    return amtFormat;
  },

  /**
   * get currency based on country
   * @returns {string}
   */
  getCurrency: function (countryCode) {
    let currency = "";
    const countryCodeCurrencyMapping = {
      id: "IDR",
      kr: "KRW",
      au: "AUD",
      th: "THB",
      ph: "PHP",
      hk: "HKD",
      sg: "SGD",
      my: "MYR",
      mo: "MOP",
      in: "INR",
      vn: "VND"
    };
    currency = countryCodeCurrencyMapping[countryCode];
    return currency;
  },
};

export default filters;
